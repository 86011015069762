<script>
import { required, sameAs } from 'vuelidate/lib/validators';

import { authFackMethods, notificationMethods } from '@/state/helpers';
import { showMessage } from '@/utils/messages';

export default {
    data() {
        return {
            password: '',
            password_confirm: '',
            token: this.$route.query.token,
            submitted: false,
            loading: false
        };
    },
    computed: {
        notification() {
            return this.$store ? this.$store.state.notification : null;
        }
    },
    created() {
        document.body.classList.add('auth-body-bg');
    },
    validations: {
        password: {
            required,
            validate() {
                if (this.password && this.password.length < 255) {
                    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;
                    if (passwordRegex.test(this.password)) {
                        return true;
                    } else {
                        return false;
                    }
                }
                return true;
            }
        },
        password_confirm: {
            required,
            sameAsPassword: sameAs('password'),
            validate() {
                if (this.password_confirm && this.password_confirm.length < 255) {
                    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;
                    if (passwordRegex.test(this.password_confirm)) {
                        return true;
                    } else {
                        return false;
                    }
                }
                return true;
            }
        }
    },
    methods: {
        ...authFackMethods,
        ...notificationMethods,
        // Try to log the user in with the username
        // and password they provided.
        tryToLogIn() {
            if (this.loading) return;
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            } else {
                if (this.password && this.password_confirm) {
                    const obj = {
                        password: this.password,
                        password_confirm: this.password_confirm,
                        token: this.token
                    };
                    this.loading = true;
                    this.resetPass(obj).then((data) => {
                        if (data.code == 200) {
                            this.$router.push({ path: '/login/' }).then(() => {
                                showMessage(data.code, this.$bvToast, 'パスワードが更新されました。');
                            });
                        }
                        if (data.code == 422) {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                        this.loading = false;
                    });
                }
            }
        }
    }
};
</script>

<template>
    <div>
        <div>
            <div class="container-fluid p-0" style="background-color: #f9fafb">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="authentication-page-content min-vh-100 d-flex align-items-center justify-content-center">
                            <div class="d-flex align-items-center justify-content-center flex-wrap-reverse">
                                <div class="form-block_account">
                                    <div class="col-md-12 text-center mb-3">
                                        <img src="@/assets/images/logo-paldia.png" alt="Image" class="img-fluid" />
                                    </div>
                                    <div class="mb-4">
                                        <h4 class="font-size-18 mt-4">パスワードの再設定</h4>
                                        <p class="text-muted">新しいパスワードを入力してください</p>
                                    </div>
                                    <form class="needs-validation" @submit.prevent="tryToLogIn" autocomplete="off">
                                        <div class="form-group auth-form-group-custom mb-4">
                                            <i class="ri-lock-2-line auti-custom-input-icon align-middle mr-1"></i>
                                            <label for="userpassword1"
                                                >新しいパスワード
                                                <span class="text-danger">*</span>
                                            </label>
                                            <input
                                                v-model="password"
                                                type="password"
                                                class="form-control"
                                                id="userpassword1"
                                                placeholder="*****************"
                                                :class="{
                                                    'is-invalid': submitted && $v.password.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.password.$error && !id" class="invalid-feedback">
                                                <span v-if="!$v.password.required">新しいパスワード を入力してください。</span>
                                                <span v-if="!$v.password.validate"
                                                    >パスワードは大文字、小文字、数字、特殊文字を含む8～32文字以内で入力してください。</span
                                                >
                                            </div>
                                        </div>

                                        <div class="form-group auth-form-group-custom mb-4">
                                            <i class="ri-lock-2-line auti-custom-input-icon align-middle mr-1"></i>
                                            <label for="userpassword">新しいパスワード <span class="text-danger">*</span> （確認）</label>
                                            <input
                                                v-model="password_confirm"
                                                type="password"
                                                class="form-control"
                                                id="userpassword"
                                                placeholder="*****************"
                                                :class="{
                                                    'is-invalid': submitted && $v.password_confirm.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.password_confirm.$error && !id" class="invalid-feedback">
                                                <span v-if="!$v.password_confirm.required">新しいパスワード（確認）を入力してください。 </span>
                                                <span v-if="!$v.password_confirm.validate"
                                                    >パスワードは大文字、小文字、数字、特殊文字を含む8～32文字以内で入力してください。</span
                                                >
                                                <span v-else-if="!$v.password_confirm.sameAsPassword"
                                                    >新しいパスワードと新しいパスワード（確認）が一致しません。
                                                </span>
                                            </div>
                                        </div>

                                        <div class="d-flex mb-5 align-items-center">
                                            パスワードを覚えましたか？
                                            <router-link tag="a" to="/login" class="text-danger">
                                                <i class="mdi mdi-lock mr-1"></i> ログイン
                                            </router-link>
                                        </div>

                                        <div class="mt-4 text-center">
                                            <button class="btn btn-pill text-white btn-block btn-primary" type="submit">送信</button>
                                        </div>

                                        <div class="pb-5"></div>

                                        <!-- <span class="d-block text-center my-4 text-muted"> or sign in with</span> -->

                                        <!-- <div class="social-login text-center">
                                            <a href="#" class="facebook">
                                                <span class="icon-facebook mr-3"><i class="ri-facebook-circle-fill"></i></span>
                                            </a>
                                            <a href="#" class="twitter">
                                                <span class="icon-twitter mr-3"><i class="ri-twitter-fill"></i></span>
                                            </a>
                                            <a href="#" class="google">
                                                <span class="icon-google mr-3"><i class="ri-google-fill"></i></span>
                                            </a>
                                        </div> -->
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
